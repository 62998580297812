$(window).on('load', function () {
  // ページのURLを取得
  const url = $(location).attr('href'),
    // headerの高さを取得してそれに30px追加した値をheaderHeightに代入
    headerHeight = $('header').outerHeight() + 30;

  // urlに「#」が含まれていれば
  if (url.indexOf("#") != -1) {
    // urlを#で分割して配列に格納
    const anchor = url.split("#"),
      // 分割した最後の文字列（#◯◯の部分）をtargetに代入
      target = $('#' + anchor[anchor.length - 1]),
      // リンク先の位置からheaderHeightの高さを引いた値をpositionに代入
      position = Math.floor(target.offset().top) - headerHeight;
    // positionの位置に移動
    $("html, body").animate({ scrollTop: position }, 0);
  }
});


$('.p-single .post .block').find('a[href^="#"]').on('click', function () {
  console.log('kita');
  const speed = 600;
  let href = $(this).attr('href');
  let nOffset = $('header').height() + 60;
  let target = $(href == '#' || href == '' ? 'html' : href);
  let position = target.offset().top;
  $('body,html').animate({ scrollTop: position - nOffset }, speed, 'swing');
  return false;
});

