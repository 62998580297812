let ptBtn = $('.pagetop');
let ptFlg = false;
$(window).on('scroll', function () {
  scrollHeight = $(document).height();
  scrollPosition = $(window).height() + $(window).scrollTop();
  if ($(this).scrollTop() > 300) {
    if (ptFlg == false) {
      ptBtn.stop().fadeIn(200);
      ptFlg = true;
    }
  } else {
    if (ptFlg == true) {
      ptBtn.stop().fadeOut(200);
      ptFlg = false;
    }
  };
});
