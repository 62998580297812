
const $ = require('jquery');
const jQueryBridget = require('jquery-bridget');
const InfiniteScroll = require('infinite-scroll/');
jQueryBridget('infiniteScroll', InfiniteScroll, $);


const infScroll = new InfiniteScroll('#container', {
  path: '.pagination_next a',
  append: '.item',
  button: '.pagination_next a',
  scrollThreshold: false,
  status: '.page-load-status',
  checkLastPage: '.pagination_next a',
  history: false,
});
infScroll.on('request', function () {
  $('.pagination_next a').css({ 'opacity': 0 });
  $('.inview').off('inview');
});
infScroll.on('append', function () {
  $('.inview').on('inview', function () {
    $(this).addClass('fadeInUp');
  });
  $('.inviewL').on('inview', function () {
    $(this).addClass('fadeInLeft');
  });
  $('.inviewR').on('inview', function () {
    $(this).addClass('fadeInRight');
  });
  $('.pagination_next a').css({ 'opacity': 1 });
});


