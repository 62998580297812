function moveAnimation() {

  var randomElm = $('.momoArea');//親要素取得
  var randomElmChild = $(randomElm).children();//親の子要素を取得
  if (!$(randomElm).hasClass('play')) {	//親要素にクラス名playが付いてなければ処理をおこなう
    randomAnime();
  }

  function randomAnime() {
    $(randomElm).addClass('play');//親要素にplayクラスを付与
    var rnd = Math.floor(Math.random() * randomElmChild.length); //配列数からランダム数値を取得
    var moveData = 'fadeUp';//アニメーション名＝CSSのクラス名を指定
    $(randomElmChild[rnd]).addClass(moveData);//アニメーションのクラスを追加
    randomElmChild.splice(rnd, 1);//アニメーション追加となった要素を配列から削除
    if (randomElmChild.length == 0) {//配列の残りがあるか確認
      $(randomElm).removeClass('play');//なくなった場合は親要素のplayクラスを削除
    } else {
      setTimeout(function () { randomAnime(); }, 200);	//0.5秒間隔でアニメーションをスタートさせる。※ランダムのスピード調整はこの数字を変更させる
    }
  }
}

var animeFlag = true;
$(window).on('load', function () {
  moveAnimation();
});
