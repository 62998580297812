import { gsap } from 'gsap';
import gsapCore from 'gsap/gsap-core';
import Scrolltrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(Scrolltrigger);
gsap.config({ nullTargetWarn: false, });

let menuFlg = true;

$(document).on('click', '.menu-btn', function () {
  let $this = $(this);
  let $tar = $this.next();
  $this.find('.menu-trigger').stop().toggleClass('active');
  if (menuFlg) {
    gsap.to($tar, {
      autoAlpha: 1,
      duration: 0.3
    });
    //$this.find('.word').text('CLOSE');
    //$this.find('.word').addClass('active');
    $('body').addClass('stopscroll');
    $('html').addClass('stopscroll');
    $('.wrapper').addClass('stopscroll');
    menuFlg = false;


  } else {

    gsap.to($tar, {
      autoAlpha: 0,
      duration: 0.3
    });
    //$(this).find('.word').text('MENU');
    //$(this).find('.word').removeClass('active');
    $('body').removeClass('stopscroll');
    $('html').removeClass('stopscroll');
    $('.wrapper').removeClass('stopscroll');
    menuFlg = true;
  }
  return false;
});
$(document).on('click', '.is_home', function () {
  if (menuFlg == false) {
    gsap.to('.navArea', {
      autoAlpha: 0,
      duration: 0.3
    });
    $('.menu-btn').find('.menu-trigger').stop().removeClass('active');
    $('body').removeClass('stopscroll');
    $('html').removeClass('stopscroll');
    $('.wrapper').removeClass('stopscroll');
    menuFlg = true;
  }

});

$(document).on('click', '.closeBtn', function () {
  if (menuFlg == false) {
    gsap.to('.navArea', {
      autoAlpha: 0,
      duration: 0.3
    });
    $('.menu-btn').find('.menu-trigger').stop().removeClass('active');
    $('body').removeClass('stopscroll');
    $('html').removeClass('stopscroll');
    $('.wrapper').removeClass('stopscroll');
    menuFlg = true;
    return false;
  }

});




$(function () {
  var bnr = $('.top_bnr');
  var hide = $('.hide');
  var pos = 0;
  let scFlg = false;

  $(window).on('scroll', function () {

    var elemTop = 100;
    var scroll = $(window).scrollTop();

    if (0 > scroll - pos) {
      if (scFlg == false) {
        bnr.addClass('is_scroll');
        scFlg = true;
      }
      //bnr.stop().fadeIn(100);
      //bnr.removeClass(hide);
    } else if (elemTop > scroll) {
      //bnr.addClass(hide);
      //bnr.stop().fadeOut(100);
      if (scFlg == true) {
        bnr.removeClass('is_scroll');
        scFlg = false;
      }
    }
    else {
      //bnr.addClass(hide);
      if (scFlg == true) {
        bnr.removeClass('is_scroll');
        scFlg = false;
      }
      //bnr.stop().fadeOut(100);
    }
    pos = scroll;

  });

  (function () {
    var newEvent = new $.Event('scrollstop'),
      timer;
    function newEventTrigger() {
      if (timer) clearTimeout(timer);
      timer = setTimeout(function () {
        $(window).trigger(newEvent)
      }, 1000);
    }
    $(window).on('scroll', newEventTrigger);
  })();

  $(window).on('scrollstop', function () {
    //bnr.stop().fadeOut(100);
    if (scFlg == true) {
      bnr.removeClass('is_scroll');
      scFlg = false;
    }
  });

});


